import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { NewPolicyModel } from '@cogent/shared/models/sales/new-policy-legacy.model';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewCoverageDialogComponent } from '@cogent/client/shared/components/service/view-coverage/view-coverage.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesItemModel } from '@cogent/shared/models/plans/sales-item.model';
import { PlanApiService } from '@cogent/client/shared/services/api/plan-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { PromotionCodeSummary } from '@upkeeplabs/models/cogent';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { Address, Entity } from '@upkeeplabs/models/cogent';
import { PropertyMetaModel } from '@cogent/shared/models/common/property-meta.model';
import { StripeCard } from '@upkeeplabs/models/cogent';
import { CoverageType, PlanClient } from '@cogent/client/shared/models/plans/plan-client.model';
import { MatTabsModule } from '@angular/material/tabs';
import { AddressEditorComponent } from '@cogent/client/shared/components/misc/address-editor/address-editor.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { PlanOfferingsModule } from '@cogent/client/shared/components/plans-and-coverage/plan-offerings1/plan-offerings.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { PaymentMethodEntryComponent } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
import { DisplayTotalModule } from '@cogent/client/shared/components/data-visualization/display-total/display-total.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OrderSummaryComponent } from '@cogent/client/apps/homeowner/sales/order-summary/order-summary.component';

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.css'],
    standalone: true,
    imports: [
        MatTabsModule,
        AddressEditorComponent,
        CommonModule,
        MatIconModule,
        PlanOfferingsModule,
        MatCheckboxModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        PaymentMethodEntryModule,
        PaymentMethodEntryComponent,
        DisplayTotalModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        OrderSummaryComponent
    ]
})
export class OrderComponent implements OnInit {

    settingValues: boolean;
    coverageType: CoverageType = CoverageType.Homeowner;
    newPolicy: NewPolicyModel = new NewPolicyModel();
    selectedPlan: PlanClient;
    selectedPlanDelayed: PlanClient;
    plans: PlanClient[];
    selectedIndex = 0;
    buyersEntity: Entity;
    canCoverAddress: boolean;
    validatingAddress: boolean;
    numbers = [0, 1, 2, 3, 4, 5, 6];
    saving = false;
    newCustomerId: string = UtilitiesService.newid();
    minStartDate: Date = new Date();
    customerId: string;
    selectedCard: StripeCard;
    creditCardValid = false;
    submitting = false;
    loading = false;
    propertyMeta: PropertyMetaModel;
    promotionCode: PromotionCodeSummary;
    promotionCodeSearch: string;
    isLegacy = true;

    constructor(
        private customerRepository: CustomerRepositoryService,
        private dialog: DialogsService,
        private mdDialog: MatDialog,
        private router: Router,
        private missionService: MissionService,
        private activatedRoute: ActivatedRoute,
        private entityApi: EntityApiService,
        private planApi: PlanApiService,
    ) {
        this.entityApi.getLoggedInUser().then(user => {
            this.customerId = user.id;
        });
    }

    ngOnInit() {
        document.location.hash = '0';
        this.newPolicy.policy.isMonthly = true;

    }


    getSampleContractUrl(item: any) {
        return ApiService.endPointNode + `Plan/contract/pdf/${item.id}`;
    }


    async updateSelection() {
        if (this.settingValues) {
            return;
        }

        const age = new Date().getFullYear() - this.newPolicy.property.yearBuilt;
        this.plans = await this.planApi.getOfferedPlans(this.coverageType,
            this.newPolicy.property.dwellingType as any,
            this.newPolicy.property.postalCode,
            this.newPolicy.property.squareFeet, age, null, this.newPolicy.property.latitude, this.newPolicy.property.longitude);
        this.plans = this.plans.filter(i => i.showOnWeb);
    }

    async findPromotionCode() {
        this.promotionCode = null;
        const code = await this.customerRepository.getPromotionCode(this.promotionCodeSearch, this.coverageType);
        this.promotionCode = code;
        if (!code) {
            this.dialog.alert('Not Found', 'Sorry, that promotion code could not be found');
        }
        this.promotionCodeSearch = '';
    }

    get adjustmentAmount() {
        if (!this.promotionCode || !this.selectedPlan) {
            return 0;
        }

        if (this.promotionCode.priceAdjustmentType === 'Flat') {
            return this.newPolicy.policy.isMonthly ? this.promotionCode.priceAdjustment / this.selectedPlan.monthsOfCoverage : this.promotionCode.priceAdjustment;
        } else if (this.promotionCode.priceAdjustmentType === "Percent") {
            return parseFloat((this.promotionCode.priceAdjustment * this.planBasePrice).toFixed(2));
        } else if (this.promotionCode.priceAdjustmentType === "Months") {
            this.newPolicy.policy.isMonthly = true;
            return 0;
        }
    }

    get addressValid() {
        return this.newPolicy.property.address1 &&
            this.newPolicy.property.city &&
            this.newPolicy.property.state &&
            this.newPolicy.property.postalCode &&
            this.newPolicy.property.dwellingType &&
            this.newPolicy.property.squareFeet;
    }

    get selectedCardTypeUrl() {
        if (!this.selectedCard) {
            return null;
        }

        if (this.selectedCard.brand === 'Visa') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/visa.png';
        } else if (this.selectedCard.brand === 'Discover') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/discover.png';
        } else if (this.selectedCard.brand === 'American Express') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/americanexpress.png';
        } else if (this.selectedCard.brand === 'MasterCard') {
            return 'https://elevateh.blob.core.windows.net/cdn/images/customer-portal/mastercard.png';
        }
    }

    get addressFilledOut() {
        return this.newPolicy.property.address1 &&
            this.newPolicy.property.city &&
            this.newPolicy.property.state &&
            this.newPolicy.property.postalCode;
    }

    async validateAddress() {
        this.validatingAddress = true;
        const result = await this.customerRepository.checkForCoverage(this.newPolicy.property);

        this.canCoverAddress = result.canCover;

        if (this.canCoverAddress) {
            this.selectedIndex = 1;
        }

        if (result.outOfArea) {
            this.dialog.alert('Out of area', 'Sorry, the postal code ' + this.newPolicy.property.postalCode
                + ' is out of the coverage area.');
        } else if (result.existingCoverage) {
            this.canCoverAddress = true;
            this.selectedIndex = 1;
            setTimeout(() => {
                this.dialog.alert('Warning', 'There is already an active policy at that address');
            }, 500);
        }
        this.validatingAddress = false;
    }

    async useMailingAddress() {
        const user = await this.entityApi.getLoggedInUser();
        const address: Address = await this.customerRepository.getCustomerMailingAddress(user.id);

        this.newPolicy.property.address1 = address.address1;
        this.newPolicy.property.address2 = address.address2;
        this.newPolicy.property.city = address.city;
        this.newPolicy.property.state = address.state;
        this.newPolicy.property.postalCode = address.postalCode;

        this.propertySelected();
    }

    planSelected(plan) {
        this.newPolicy.policy.planId = plan.id;
        // setTimeout(() => {
        //     this.selectedIndex++;
        // }, 1);
        this.selectedIndex++;
        const effectiveDate = new Date();
        effectiveDate.setDate(effectiveDate.getDate() + plan.homeownerWaitingPeriod);
        this.newPolicy.policy.effectiveDate = effectiveDate;
        this.minStartDate = new Date(effectiveDate);
        setTimeout(() => this.selectedPlanDelayed = plan, 1000);
    }

    get canSubmit() {
        return this.newPolicy
            && this.newPolicy.property
            && this.newPolicy.property.isFilledOut
            && this.selectedCard
            && this.selectedPlan;
    }

    paymentValid(isValid) { this.creditCardValid = isValid; }

    @HostListener('window:hashchange', ['$event'])
    watchUrlHash(event) {
        let hash = window.location.hash;
        if (hash) {
            hash = hash.replace('#', '');
        }


        let hashNumber = parseInt(hash, 10);

        if (isNaN(hashNumber)) {
            hashNumber = 0;
        }

        if (!isNaN(hashNumber)) {
            this.selectedIndex = hashNumber;
        }

    }

    setHash(index) {
        document.location.hash = index;
        if (index <= 1) {
            this.selectedPlanDelayed = null;
        }
    }

    get planBasePrice(): number {
        if (!this.selectedPlan) {
            return 0;
        }

        let amount = 0;
        if (this.newPolicy.policy.isMonthly) {
            amount = this.selectedPlan.priceRecurring;
            this.selectedPlan.optionalItems.forEach(optionalItem => {
                if (optionalItem.isDefault) {
                    amount -= optionalItem.priceRecurring;
                }
            });
        } else {
            amount = this.selectedPlan.price;
            this.selectedPlan.optionalItems.forEach(optionalItem => {
                if (optionalItem.isDefault) {
                    amount -= optionalItem.price;
                }
            });
        }
        return amount;
        // return this.newPolicy.policy.isMonthly ? (this.selectedPlan.priceRecurring) : this.selectedPlan.price;
    }

    get optionsPrice(): number {
        if (!this.selectedPlan) {
            return;
        }

        let total = 0;
        this.selectedPlan.optionalItems.filter(i => i.selected).forEach(item => total += item.quantity
            * (this.newPolicy.policy.isMonthly ? item.priceRecurring : item.price));
        return total;
    }

    get totalPlanPrice(): number {
        return this.planBasePrice + this.adjustmentAmount + this.optionsPrice;
    }

    get selectedOptions(): SalesItemModel[] {
        const results: SalesItemModel[] = [];

        if (this.selectedPlan) {
            this.selectedPlan.optionalItems.filter(i => i.selected && i.quantity > 0).forEach(i => results.push(i));
        }
        return results;
    }

    optionSelected(option: SalesItemModel) {
        if (option.selected && option.quantity === 0) {
            option.quantity = 1;
        }
        if (!option.selected) {
            option.quantity = 0;
        }
    }

    addressCompleteChange(complete: boolean) {
        if (complete && !this.propertyMeta) {
            this.propertySelected();
        }
    }

    async propertySelected() {
        this.propertyMeta = await this.customerRepository.getPropertyMeta(this.newPolicy.property.address1, this.newPolicy.property.postalCode);
        this.settingValues = true;
        this.newPolicy.property.squareFeet = this.propertyMeta.sqft;
        this.newPolicy.property.dwellingType = this.propertyMeta.useCode;
        this.newPolicy.property.latitude = this.propertyMeta.lat;
        this.newPolicy.property.longitude = this.propertyMeta.lon;
        this.newPolicy.property.yearBuilt = this.propertyMeta.yearBuilt;
        this.settingValues = false;
        this.updateSelection();
    }

    get propertyStreetView(): string {
        if (!this.newPolicy.property || !this.newPolicy.property.address1) {
            return null;
        }

        const url = 'https://maps.googleapis.com/maps/api/streetview?size=150x150&location='
            + this.newPolicy.property.address1 +
            ' ' + this.newPolicy.property.city + ' ' + this.newPolicy.property.state
            + ' ' + this.newPolicy.property.postalCode + '&key=AIzaSyB1xEizJ3bh0fvxTQkB-Pit7cPkzgr-tl0';

        return encodeURI(url);
    }

    showCoverage(item) {
        this.mdDialog.open(ViewCoverageDialogComponent, { data: item.planItemId });
    }

    get canCreatePolicy() {
        if (!this.newPolicy.policy.planId) {
            return false;
        }
        if (!this.newPolicy.holder.name) {
            return false;
        }
        if (!this.newPolicy.holder.address.isFilledOut) {
            return false;
        }
        if (!this.newPolicy.property.isFilledOut) {
            return false;
        }

        return true;
    }

    async createOrder() {
        this.submitting = true;
        this.newPolicy.holderAddress = this.newPolicy.holder.address;
        this.newPolicy.holderPhoneNumbers = this.newPolicy.holder.phoneNumbers;
        this.newPolicy.options = this.selectedOptions;
        this.newPolicy.coverageType = 'Homeowner';
        this.newPolicy.selectedCard = this.selectedCard;
        if (this.promotionCode) {
            this.newPolicy.promotionCode = this.promotionCode.code;
        }

        const user = await this.entityApi.getLoggedInUser();
        this.newPolicy.policy.holderId = user.id;
        delete this.newPolicy.holder;
        delete this.newPolicy.holderAddress;

        const newPolicy = await this.customerRepository.saveNewPolicy(this.newPolicy);
        this.submitting = false;
        this.missionService.showSuccessToast('Your coverage has been created.');

        this.router.navigateByUrl(`../view-property/${newPolicy.id}`);
    }
}
