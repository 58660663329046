import { FunctionRunner } from "./function-runner.logic";

export interface IStepExecutor {
    executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams>;
    type: string;
}

export interface ISMSQuestion {
    respondToAnswer(answer: any, inputParams: ExecutorInputParams): Promise<ExecutorOutputParams>;
}

export class StartChatArgs {
    contactFlowId?: string;
    inputParams: ExecutorInputParams;
    isAI?: boolean;
    prompt?: string;
    aiMessages?: any[];
    intentPrompt?: string;
}

export interface IQuestionRenderer {
    renderQuestion(args: QuestionRenderInputArgs): void;
    questions: QuestionRenderInputArgs[];
    answerQuestion(value: any, question: QuestionRenderInputArgs);
    startChat(args: StartChatArgs): void;
}

export class QuestionRenderOutputArgs {
    value: any;
}

export class QuestionRenderInputArgs {
    functionCell: FunctionCell;
    inputs: ExecutorInputParams;
    currentExecutor: IStepExecutor;
    answer: any;
    required: boolean;
    saving?: boolean;
}

export class ExecutorInputParams {
    inputs: any[];
    currentCell: FunctionCell;
    dependencies: any;
    objectInScope: any;
    currentRunner: FunctionRunner;
}

export class ExecutorOutputParams {
    result: any;
    outputPort: PortItem;
    nextFunction: FunctionCell;
    quit: boolean;
}

export class FunctionCell {
    type: string;
    source: LinkEndpoint;
    target: LinkEndpoint;
    z: number;
    id: string;
    attrs: CellAttributes;
    ports: CellPort;
    position: CellPosition;
    size: CellSize;
    angle: number;
    custom: CustomData;
    showActivity: boolean;
    showActivityMessage: string;
    inputParams?: ExecutorInputParams;
    noResetLoop?: boolean;
}

export class CustomFormQuestions {
    questions: FunctionCell[];
    completeCallback: () => void;
    //cancelledCallback: ()=> void;
    endFormFunction: FunctionCell;
    executorInputParams: ExecutorInputParams;
    functionRunner: FunctionRunner;
}

export class CellPosition {
    x: number;
    y: number;
}

export class CustomData {
    inputs: CustomInputs[];
    text: string;
    selectedItems: any;
    answers: string[];
}

export class CustomInputs {
    id: string;
    value: string;
    portConnected: boolean;
    label: string;
}

export class CellSize {
    width: number;
    height: number;
}

export class LinkEndpoint {
    id: string;
    magnet: string;
    port: string;
}

export class CellAttributes {
    line: CellAttributesLine;
    label: any;
}

export class CellAttributesLine {
    stroke: string;
    strokeWIdth: number;
    strokeDashArray: string;
}

export class CellPort {
    groups: CellPortGroup;
    items: PortItem[];
}

export class PortItem {
    group: string;
    attrs: PortItemAttributes;
    id: string;
}

export class PortItemAttributes {
    label: PortItemLabel;
}

export class PortItemLabel {
    text: string;
}

export class CellPortGroup {
    in: PortDefinition;
    out: PortDefinition;
    transmissionIn: PortDefinition;
    transmissionOut: PortDefinition;
}

export class PortDefinition {
    label: PortLabel;
    position: PortPosition;
    attrs: PortAttributes;
    z: number;
}

export class PortLabel {
    position: PortLabelPosition;
    markup: PortLabelMarkup;
}

export class PortLabelPosition {
    name: string;
}

export class PortLabelMarkup {
    tagName: string;
    selector: string;
}

export class PortPosition {
    name: string;
}

export class PortAttributes {
    portBody: PortBody;
}

export class PortBody {
    magnet: string;
    r: number;
    cy: number;
    cx: number;
    fill: string;
}

export const typeMap = {
    start: '/assets/images/function-icons/start.png',
    ifThen: '/assets/images/function-icons/if-else.png',
    isNull: '/assets/images/function-icons/is-null.png',
    sendEmail: '/assets/images/function-icons/send-email.png',
    equals: '/assets/images/function-icons/equals.png',
    getObjectInScope: '/assets/images/function-icons/object-in-scope.png',
    getObjectValue: '/assets/images/function-icons/get-object-value.png',
    loop: '/assets/images/function-icons/loop.png',
    first: '/assets/images/function-icons/first.png',
    max: '/assets/images/function-icons/max.png',
    map: '/assets/images/function-icons/map.png',
    min: '/assets/images/function-icons/min.png',
    avg: '/assets/images/function-icons/avg.png',
    indexOf: '/assets/images/function-icons/index-of.png',
    join: '/assets/images/function-icons/join.png',
    pop: '/assets/images/function-icons/pop.png',
    contact: '/assets/images/function-icons/concat.png',
    length: '/assets/images/function-icons/length.png',
    wait: '/assets/images/function-icons/wait.png',
    switch: '/assets/images/function-icons/switch.png',
    roboDial: '/assets/images/function-icons/auto-call.png',
    broadcastApplicationMessage: '/assets/images/function-icons/broadcast.png',
    dialNumber: '/assets/images/function-icons/phone.png',
    runWorkflow: '/assets/images/function-icons/process.png',
    sendAppNotification: '/assets/images/function-icons/app-notification.png',
    sendCogentNotification: '/assets/images/function-icons/cogent-notification.png',
    sendSMSNotification: '/assets/images/function-icons/sms.png',
    slyDial: '/assets/images/function-icons/sly-dial.png',
    endProcess: '/assets/images/function-icons/end-process.png',
    transfer: '/assets/images/function-icons/transfer.png',
    stopProcess: '/assets/images/function-icons/stop-flow.png',
    singleSelect: '/assets/images/function-icons/single-select.png',
    authorizationRepairItemAttribute: '/assets/images/function-icons/attribute.png',
    checkList: '/assets/images/function-icons/check-list.png',
    multiLineTextBox: '/assets/images/function-icons/multi-line-textbox.png',
    textBox: '/assets/images/function-icons/text-box.png',
    numberBox: '/assets/images/function-icons/number-box.png',
    repairItem: '/assets/images/function-icons/repair-item.png',
    brand: '/assets/images/function-icons/brand.png',
    hasRepairItem: '/assets/images/function-icons/has-repair-item.png',
    dateBox: '/assets/images/function-icons/date-box.png',
    uploadImage: '/assets/images/function-icons/upload-image.png',
    weatherForecast: '/assets/images/function-icons/weather.png',
    showActivityIndicator: '/assets/images/function-icons/show-activity-indicator.png',
    hideActivityIndicator: '/assets/images/function-icons/hide-activity-indicator.png',
    changeLane: '/assets/images/function-icons/change-lane.png',
    navigateToUrl: '/assets/images/function-icons/navigate-to-url.png',
    refreshAll: '/assets/images/function-icons/refresh-all.png',
    refresh: '/assets/images/function-icons/refresh.png',
    removeFromQueue: '/assets/images/function-icons/remove-from-queue.png',
    alertDialog: '/assets/images/function-icons/alert-dialog.png',
    confirmDialog: '/assets/images/function-icons/confirm-dialog.png',
    toastMessage: '/assets/images/function-icons/toast-message.png',
    toastSuccessMessage: '/assets/images/function-icons/toast-success.png',
    undo: '/assets/images/function-icons/undo.png',
    beginForm: '/assets/images/function-icons/begin-form.png',
    richText: '/assets/images/function-icons/rich-text.png',
    slide: '/assets/images/function-icons/slider.png',
    emailBox: '/assets/images/function-icons/email-box.png',
    phoneBox: '/assets/images/function-icons/phone-box.png',
    checkBox: '/assets/images/function-icons/slide-toggle.png',
    endForm: '/assets/images/function-icons/end-form.png',
    showMessage: '/assets/images/function-icons/message.png',
    heading: '/assets/images/function-icons/heading.png',
    separator: '/assets/images/function-icons/separator.png',
    tagEntry: '/assets/images/function-icons/tag-entry.png',
    preferredTimeSlot: '/assets/images/function-icons/preferred-time-slots.png',
    createTask: '/assets/images/function-icons/create-task.png',
    createObject: '/assets/images/function-icons/create-binding-object.png',
    stringTemplateBinding: '/assets/images/function-icons/template-binding.png',
    rest: '/assets/images/function-icons/rest.png',
    createConnectTask: '/assets/images/function-icons/connect-task.png',
    autoPhoneCall: '/assets/images/function-icons/auto-call.png',
    greaterThan: '/assets/images/function-icons/greater-than.png',
    greaterThanEqual: '/assets/images/function-icons/greater-than-equal.png',
    or: '/assets/images/function-icons/or.png',
    ifElse: '/assets/images/function-icons/if-else.png',
    youTube: '/assets/images/function-icons/you-tube.png',
    showImage: '/assets/images/function-icons/show-image.png',
    stringify: '/assets/images/function-icons/stringify.png',
    parse: '/assets/images/function-icons/parse.png',
    log: '/assets/images/function-icons/log.png',
    push: '/assets/images/function-icons/push.png',
    arrayAccessor: '/assets/images/function-icons/array-accessor.png',
    toString: '/assets/images/function-icons/to-string.png',
    smsBotBegin: '/assets/images/function-icons/sms-bot-begin.png',
    stringConcat: '/assets/images/function-icons/string-concat.png',
    smsBotEnd: '/assets/images/function-icons/sms-bot-end.png',
    add: '/assets/images/function-icons/add.png',
    subtract: '/assets/images/function-icons/subtract.png',
    multiply: '/assets/images/function-icons/multiply.png',
    divide: '/assets/images/function-icons/divide.png',
    getDate: '/assets/images/function-icons/get-date.png',
    dateAdd: '/assets/images/function-icons/date-add.png',
    addTags: '/assets/images/function-icons/add-tags.png',
    removeTags: '/assets/images/function-icons/remove-tags.png',
    hasTags: '/assets/images/function-icons/has-tags.png',
    phoneLink: '/assets/images/function-icons/phone-link.png',
    confirmButton: '/assets/images/function-icons/confirm-button.png',
    getEnvVariable: '/assets/images/function-icons/get-env-variable.png',
    note: '/assets/images/function-icons/note.png',
    executionContext: '/assets/images/function-icons/execution-context.png',
    saveEnvVariable: '/assets/images/function-icons/save-env-variable-2.png',
    changeJobStatus: '/assets/images/function-icons/set-job-status.png',
    setPropertyValue: '/assets/images/function-icons/set-property-value.png',
    saveObject: '/assets/images/function-icons/save-object.png',
    parseInt: '/assets/images/function-icons/parse-int.png',
    parseFloat: '/assets/images/function-icons/parse-float.png',
    subFunction: '/assets/images/function-icons/sub-function.png',
    subFunctionEnd: '/assets/images/function-icons/end-sub-function.png',
    getSubFunctionInputs: '/assets/images/function-icons/get-sub-function-inputs.png',
    cleanUp: '/assets/images/function-icons/clean-up.png',
    getObject: '/assets/images/function-icons/get-object.png',
    evaluate: '/assets/images/function-icons/evaluate.png',
    clipboardCopy: '/assets/images/function-icons/clipboard-copy.png',
    assignJob: '/assets/images/function-icons/assign-job.png',
    getAllVariables: '/assets/images/function-icons/get-all-variables.png',
    startChat: '/assets/images/function-icons/start-chat.png',
    starRating: '/assets/images/function-icons/star-rating.png',
    getJobItem: '/assets/images/function-icons/job-item-selection.png',
    jobItemQuestions: '/assets/images/function-icons/job-questions.png',
    getPolicies: '/assets/images/function-icons/get-subscriptions.png',
    getCreditCards: '/assets/images/function-icons/get-credit-card.png',
    newArray: '/assets/images/function-icons/new-array.png',
    confirmContact: '/assets/images/function-icons/confirm-contact.png',
    newid: '/assets/images/function-icons/newid.png',
    ternaryOperator: '/assets/images/function-icons/ternary-operator.png',
    hyperlink: '/assets/images/function-icons/hyperlink.png',
    stopConnectContact: '/assets/images/function-icons/stop-connect-contact.png',
    datesEqual: '/assets/images/function-icons/dates-equal.png',
    addressEntry: '/assets/images/function-icons/address-entry.png',
    planSelection: '/assets/images/function-icons/plan-entry.png',
    aiChat: '/assets/images/function-icons/ai-chat.png',
    setObjectInScope: '/assets/images/function-icons/set-object-in-scope.png',
    customComponent: '/assets/images/function-icons/custom-component.png',
    checkCircleAnimated: '/assets/images/function-icons/check-circle-animated.png',
    findPolicy: '/assets/images/function-icons/find-subscription.png',
    closeObjectDetail: '/assets/images/function-icons/close-object-detail.png',
    lottieFile: '/assets/images/function-icons/lottie-file.png',
    createAuthoItem: '/assets/images/function-icons/autho-line.png',
    authoItemSelection: '/assets/images/function-icons/select-autho-items.png',
    searchChipSelection: '/assets/images/function-icons/search-chip-selection.png',
    addAuthoWarning: '/assets/images/function-icons/add-autho-warning.png',
    selectRepairType: '/assets/images/function-icons/repair-type-selection.png',
    addLabor: '/assets/images/function-icons/add-labor.png',
    datePart: '/assets/images/function-icons/date-part.png',
    fileUpload: '/assets/images/function-icons/file-upload.png',
    workOrderItemTypeSelection: '/assets/images/function-icons/work-order-item-type-selection.png',
    transferToRepairType: '/assets/images/function-icons/repair-type-transfer.png',
    changeRepairType: '/assets/images/function-icons/transfer-repair-type.png'
};

export const functionToolbars = [{
    url: typeMap.start,
    hasTransmissionLineIn: false,
    hasOutputPort: false,
    inputPortLabels: [],
    transmissionOutPortLabels: [''],
    title: 'Start',
    category: 'Control Flow',
    description: 'Function starting point. There can be one and only one start block on a fuction',
},
{
    url: typeMap.endProcess,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortTypes: [''],
    inputPortLabels: ['Result'],
    transmissionOutPortLabels: [],
    title: 'End Process',
    category: 'Control Flow',
    description: 'Ends a function and optionally returns a value to a calling function'
},
// {
//     url: '/assets/images/function-icons/stop-flow.png',
//     hasTransmissionLineIn: true,
//     hasOutputPort: false,
//     inputPortLabels: [],
//     transmissionOutPortLabels: [],
//     title: 'Stop Flow',
//     category: 'Control Flow'
// },
{
    url: typeMap.ifElse,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: ['Value'],
    transmissionOutPortLabels: ['True', 'False'],
    title: 'If/Else',
    category: 'Control Flow',
    description: 'Create an if / else condition'
},
{
    url: typeMap.isNull,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: ['Expression'],
    transmissionOutPortLabels: ['True', 'False'],
    title: 'Is Null',
    category: 'Control Flow',
    description: 'Checks to see if an inputted value is null (returns true or false)'
},
{
    url: typeMap.sendEmail,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortTypes: [null, null, null, 'MULTILINE', null, null, 'MULTILINE', null, "OBJECT-TYPE-SELECTOR", 'ATTACHMENT-EDITOR'],
    inputPortLabels: ['To', 'From', 'Subject', 'Message', 'cc', 'bcc', 'x-smtpapi', 'attach to id', 'id type', 'Attachments'],
    transmissionOutPortLabels: [''],
    title: 'Send Email',
    category: 'Communication',
    description: 'Sends an email to the specified recipient(s)'
},
{
    url: typeMap.equals,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: ['Value 1', 'Value 2'],
    transmissionOutPortLabels: ['True', 'False'],
    title: 'Equals',
    category: 'Control Flow',
    description: 'Checks to see if the two inputted values are equal (returns true or false)',
},
{
    url: typeMap.executionContext,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    transmissionOutPortLabels: ['angular', 'node', 'sms', 'voice'],
    title: 'Execution Context',
    description: 'Allows you to determine if the function is executing in Angular (click) or Node (server)',
    category: 'Control Flow',
},
{
    url: typeMap.getObjectInScope,
    hasTransmissionLineIn: false,
    hasOutputPort: true,
    inputPortLabels: [],
    transmissionOutPortLabels: [],
    title: 'Get Object In Scope',
    category: 'Control Flow',
    description: 'Get the object passed into the function call',
},
{
    url: typeMap.setObjectInScope,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: ['New Value'],
    transmissionOutPortLabels: [''],
    title: 'Sets Object In Scope',
    category: 'Control Flow',
    description: 'Replace the current object in scope with the inputted value',
},
{
    url: typeMap.getObjectValue,
    hasTransmissionLineIn: false,
    hasOutputPort: true,
    inputPortLabels: ['Object', 'Field'],
    transmissionOutPortLabels: [],
    title: 'Get Object value',
    category: 'Control Flow',
    description: 'Gets a property value specified on an object',
},
{
    url: typeMap.loop,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: ['Array'],
    transmissionOutPortLabels: ['Loop', 'Completion'],
    title: 'Loop',
    category: 'Array',
    description: 'Executes a loop over the inputted array'
},
{
    url: typeMap.first,
    hasTransmissionLineIn: false,
    hasOutputPort: true,
    inputPortLabels: ['Array'],
    transmissionOutPortLabels: [],
    title: 'First',
    category: 'Array',
    description: 'Returns the first element in an array'
},
{
    url: typeMap.length,
    hasOutputPort: true,
    inputPortLabels: ['Array'],
    title: 'Length',
    category: 'Array',
    description: 'Returns the length or number of elements in an array'
},
{
    url: typeMap.map,
    hasOutputPort: true,
    inputPortLabels: ['Array', 'Field'],
    title: 'Map',
    category: 'Array',
    description: 'Performs a mapping function on an array'
},
{
    url: typeMap.max,
    hasOutputPort: true,
    inputPortLabels: ['Array'],
    title: 'Max',
    category: 'Array',
    description: 'Returns the maximum value in the specified array'
},
{
    url: typeMap.min,
    hasOutputPort: true,
    inputPortLabels: ['Array'],
    title: 'Min',
    category: 'Array',
    description: 'Returns the minimum value in the specified array'
},
{
    url: typeMap.avg,
    hasOutputPort: true,
    inputPortLabels: ['Array'],
    title: 'Averge',
    category: 'Array',
    description: 'Returns the average value of the specified numeric array'
},
{
    url: typeMap.indexOf,
    hasOutputPort: true,
    inputPortLabels: ['Array', 'Value'],
    title: 'Index Of',
    category: 'Array',
    description: 'Return the index position in an array of a specified item'
},
{
    url: typeMap.join,
    hasOutputPort: true,
    inputPortLabels: ['Array', 'Separator'],
    title: 'Join',
    category: 'Array',
    description: 'Returns an array as a string with elements separated '
},
{
    url: typeMap.pop,
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    inputPortLabels: ['Array'],
    transmissionOutPortLabels: [''],
    title: 'Pop',
    category: 'Array',
    description: 'Removes the last element from an array'
},
{
    url: typeMap.contact,
    hasOutputPort: true,
    inputPortLabels: ['Array 1', 'Array 2'],
    title: 'Concat',
    category: 'Array',
    description: 'Combines two arrays into a single array'
},
{
    url: typeMap.greaterThan,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: ['Value 1', 'Value 2'],
    transmissionOutPortLabels: ['True', 'False'],
    title: 'Greater Than',
    category: 'Control Flow',
    description: 'Compares two values to see  if the first value is greater than the second value (returns true or false)'
},
{
    url: typeMap.greaterThanEqual,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: ['Value 1', 'Value 2'],
    transmissionOutPortLabels: ['True', 'False'],
    title: 'Greater Than Or Equal',
    category: 'Control Flow',
    description: 'Compares two values to see if the first value is greater than or equal to the second value (returns true or false)'
},
{
    url: typeMap.or,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: ['Value 1', 'Value 2'],
    transmissionOutPortLabels: ['True', 'False'],
    title: 'Or Comparison',
    category: 'Control Flow',
    description: 'Checks to see if either condition is true (returns true or false)'
},
{
    url: typeMap.wait,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortTypes: ['', '', 'OBJECT-TYPE'],
    inputPortLabels: ['Seconds', 'Object Id', 'Object Type'],
    transmissionOutPortLabels: [''],
    title: 'Wait',
    category: 'Control Flow',
    description: 'Waits the inputted number of seconds before continuing the function execution'
},
{
    url: typeMap.switch,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: ['Value'],
    transmissionOutPortLabels: ['_Default'],
    title: 'switch',
    category: 'Control Flow',
    description: 'Performs a switch comparison'
},
{
    url: typeMap.autoPhoneCall,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Phone', 'Message To Read'],
    transmissionOutPortLabels: [''],
    title: 'Auto Phone Call',
    category: 'Communication',
    description: 'Places a robo dialed phone call to read off a message',
},
{
    url: typeMap.broadcastApplicationMessage,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortTypes: ['', 'MULTILINE'],
    inputPortLabels: ['Application Key', 'Message'],
    transmissionOutPortLabels: [''],
    title: 'Broadcast Application Message',
    category: 'Communication',
    description: 'Sends a message to the underlying application which can be responded to programatically'
},
{
    url: typeMap.dialNumber,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Phone #'],
    transmissionOutPortLabels: [''],
    title: 'Dial Number',
    category: 'Communication',
    description: 'Places an outgoing phone call through Amazon Connect',
},
{
    url: typeMap.subFunction,
    hasTransmissionLineIn: true,
    hasOutPort: false,
    title: 'Sub Function',
    inputPortLabels: ['Arguments'],
    category: 'Flow Control',
    transmissionOutPortLabels: ['Sub Function', 'Resume'],
    description: 'Run a sub function'
},
{
    url: typeMap.subFunctionEnd,
    hasTransmissionLineIn: true,
    hasOutPort: false,
    title: 'End Sub Function',
    category: 'Flow Control',
    description: 'Completed a sub function',
},
// {
//     url: '/assets/images/function-icons/process.png',
//     hasTransmissionLineIn: true,
//     hasOutputPort: false,
//     inputPortLabels: ['Workflow Id', 'Object Type', 'Object Id'],
//     transmissionOutPortLabels: [''],
//     title: 'Run Workflow',
//     category: 'Communication'
// },
// {
//     url: '/assets/images/function-icons/app-notification.png',
//     hasTransmissionLineIn: true,
//     hasOutputPort: false,
//     inputPortLabels: ['Message Recipients', 'Title', 'Message', 'Endpoint URL'],
//     transmissionOutPortLabels: [''],
//     title: 'Send App Notification',
//     category: 'Communication'
// },
{
    url: typeMap.sendCogentNotification,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortTypes: ['EMPLOYEE', 'MULTILINE'],
    inputPortLabels: ['Employees', 'Message', 'Endpoint URL'],
    transmissionOutPortLabels: [''],
    title: 'Send Cogent Notification',
    category: 'Communication',
    description: 'Sends a message to the specified Cogent users'
},
{
    url: typeMap.assignJob,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortTypes: ['EMPLOYEE-SINGLE-SELECT'],
    inputPortLabels: ['Employee', 'Job Id'],
    transmissionOutPortLabels: [''],
    title: 'Assign Job',
    category: 'Data',
    description: 'Assigns a job to the specified user'

},
{
    url: typeMap.sendSMSNotification,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortTypes: ['', 'MULTILINE', '', 'boolean', '', '', 'OBJECT-TYPE-SELECTOR', null],
    inputPortLabels: ['Recipients', 'Message', 'Entity Id', 'Override Send Preferences', "From", "Attach To Id", "Table of View"],
    transmissionOutPortLabels: [''],
    title: 'Send SMS Notification',
    category: 'Communication',
    description: 'Sends a message via SMS text to the specified phone number(s)'
},
{
    url: typeMap.slyDial,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Phone #', 'Message Id'],
    transmissionOutPortLabels: [''],
    title: 'Sly Dial',
    category: 'Communication',
    description: 'Sends a Sly Dial message to the specified phone number(s)'
},
{
    url: typeMap.addTags,
    hasTransmissionLineIn: true,
    inputPortTypes: ['', 'OBJECT-TYPE', 'TAGS', 'DATE'],
    inputPortLabels: ['Object Id', 'Object Type', 'Tags', 'Expiration Date'],
    transmissionOutPortLabels: [''],
    title: 'Add tags',
    category: 'Data',
    description: 'Add tags to the specified object'
},
{
    url: typeMap.removeTags,
    hasTransmissionLineIn: true,
    inputPortTypes: ['', 'OBJECT-TYPE', 'TAGS'],
    inputPortLabels: ['Object Id', 'Object Type', 'Tags'],
    transmissionOutPortLabels: [''],
    title: 'Remove tags',
    category: 'Data',
    description: 'Removes tags from the specified object'
},
{
    url: typeMap.hasTags,
    hasTransmissionLineIn: true,
    inputPortTypes: ['', 'OBJECT-TYPE', 'TAGS'],
    inputPortLabels: ['Object Id', 'Object Type', 'Tag(s)'],
    title: 'Has Tags',
    hasOutputPort: true,
    transmissionOutPortLabels: ['True', 'False'],
    category: 'Data',
    description: 'Checks to see if the tag(s) exist on an object'
},
// {
//   url: '/assets/images/form.png',
//   hasTransmissionLineIn: true,
//   hasOutputPort: true,
//   inputPortLabels: ['Form Id'],
//   transmissionOutPortLabels: [''],
//   title: 'Show Form',
//   category: 'Input'
// },
// {
//   url: '/assets/images/questionnaire.png',
//   hasTransmissionLineIn: true,
//   hasOutputPort: true,
//   inputPortLabels: ['Questionnaire Id'],
//   transmissionOutPortLabels: [''],
//   title: 'Show Questionnaire',
//   category: 'Input'
// },
// {
//   url: '/assets/images/questionnaire-answer.png',
//   hasTransmissionLineIn: false,
//   hasOutputPort: true,
//   inputPortLabels: ['Questionnaire'],
//   transmissionOutPortLabels: [],
//   title: 'Get Questionnaire Answer',
//   category: 'Output'
// },
{
    url: typeMap.transfer,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['', 'Object', 'SAVED_FUNCTION', 'boolean', '', 'OBJECT-TYPE', 'DATE'],
    inputPortLabels: ['Function JSON', 'Object In Scope', 'Saved Function', 'Run in Node', 'Object Id', 'Object Type', 'Date to Run'],
    transmissionOutPortLabels: ['Resume'],
    title: 'Transfer',
    category: 'Control Flow',
    description: 'Calls a different function'
},
// {
//   url: '/assets/images/save-env-variable.png',
//   hasTransmissionLineIn: true,
//   hasOutputPort: false,
//   inputPortLabels: ['Key', 'Value'],
//   transmissionOutPortLabels: [''],
//   title: 'Save Environment Variable',
//   category: 'Control Flow'
// },
// {
//   url: '/assets/images/read-env-variable.png',
//   hasTransmissionLineIn: false,
//   hasOutputPort: true,
//   inputPortLabels: ['Key'],
//   transmissionOutPortLabels: [],
//   title: 'Read Environment Variable',
//   category: 'Control Flow'
// },
{
    url: typeMap.singleSelect,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: ['Required', 'Label'],
    inputPortTypes: ['boolean'],
    transmissionOutPortLabels: ['_Default'],
    title: 'Single Item Select',
    category: 'Input',
    description: 'Shows a question with fixed answers and allows single answer selection'
},
{
    url: typeMap.checkList,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: ['Required', 'Show Next Button'],
    inputPortTypes: ['boolean', 'boolean'],
    transmissionOutPortLabels: [''],
    title: 'Check List',
    category: 'Input',
    description: 'Shows a question with fixed answers and allows multiple answer selection'
},
{
    url: typeMap.multiLineTextBox,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean', 'MULTILINE'],
    inputPortLabels: ['Required', 'Default Value', 'Label'],
    transmissionOutPortLabels: [''],
    title: 'Multi-line textbox',
    category: 'Input',
    description: 'Shows a question that allows multiple line text input'
},
{
    url: typeMap.textBox,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean'],
    inputPortLabels: ['Required', 'Default Value'],
    transmissionOutPortLabels: [''],
    title: 'Text Box',
    category: 'Input',
    description: 'Shows a question that allows single line text input'
},
{
    url: typeMap.numberBox,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean'],
    inputPortLabels: ['Required', 'Default Value'],
    transmissionOutPortLabels: [''],
    title: 'Number Box',
    category: 'Input',
    description: 'Shows a question that allows for numeric input',
},
{
    url: typeMap.repairItem,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean'],
    inputPortLabels: ['Required'],
    transmissionOutPortLabels: [''],
    title: 'Repair Item',
    category: 'Input',
    description: 'Shows a question that asks for a repair item',
},
{
    url: typeMap.brand,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean'],
    inputPortLabels: ['Required'],
    transmissionOutPortLabels: [''],
    title: 'Brand',
    category: 'Input',
    description: 'Shows a question that asks for the brand',
},
{
    url: typeMap.hasRepairItem,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['REPAIR_ITEM'],
    inputPortLabels: ['Repair Item Id', 'Policy Id'],
    transmissionOutPortLabels: ['True', 'False'],
    title: 'Has Repair Item',
    category: 'Control Flow',
    description: 'Checks to see if a subscription has a specified repair item'
},
{
    url: typeMap.dateBox,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean', 'Date', 'Date', 'Date'],
    inputPortLabels: ['Required', 'Default Value', 'Min', 'Max'],
    transmissionOutPortLabels: [''],
    title: 'Date Box',
    category: 'Input',
    description: 'Shows a question that allows for date input'
},
{
    url: typeMap.uploadImage,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean'],
    inputPortLabels: ['Required', 'Label'],
    transmissionOutPortLabels: [''],
    title: 'Upload Image',
    category: 'Input',
    description: 'Shows an input to upload an image'
},
{
    url: typeMap.weatherForecast,
    hasTransmissionLineIn: false,
    hasOutputPort: true,
    inputPortLabels: ['Postal Code'],
    transmissionOutPortLabels: [],
    title: 'Weather Forecast',
    category: 'Data',
    description: 'Get the weather forecast for a given zip code'
},
{
    url: typeMap.showActivityIndicator,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Message'],
    transmissionOutPortLabels: [''],
    title: 'Show Activity Indicator',
    category: 'Output',
    description: 'Shows a UI element indicating asynchronous activity'
},
{
    url: typeMap.hideActivityIndicator,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: [],
    transmissionOutPortLabels: [''],
    title: 'Hide Activity Indicator',
    category: 'Output',
    description: 'Hides the asynchronous activity indicator'
},
{
    url: typeMap.changeLane,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: [],
    transmissionOutPortLabels: [''],
    title: 'Change Lane',
    category: 'Data',
    description: 'Moves an object from one queue to another on a board',
},
{
    url: typeMap.navigateToUrl,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortTypes: [null, 'NAV_TARGET'],
    inputPortLabels: ['URL', 'Target'],
    transmissionOutPortLabels: [''],
    title: 'Navigate To Url',
    category: 'Output',
    description: 'Navigates the application to a specified URL'
},
{
    url: typeMap.refreshAll,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: [],
    transmissionOutPortLabels: [''],
    title: 'Refresh All',
    category: 'Data',
    description: 'Refreshes the entire page context',
},
{
    url: typeMap.refresh,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: [],
    transmissionOutPortLabels: [''],
    title: 'Refresh',
    category: 'Data',
    description: 'Refreshes the current object in scope'
},
{
    url: typeMap.removeFromQueue,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: [],
    transmissionOutPortLabels: [''],
    title: 'Remove From Queue',
    category: 'Data',
    description: 'Removes the current object in scope from the current queue'
},
{
    url: typeMap.alertDialog,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Subject', 'Message'],
    transmissionOutPortLabels: [''],
    title: 'Alert Dialog',
    category: 'Output',
    description: 'Shows an alert dialog message',
},
{
    url: typeMap.confirmDialog,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Subject', 'Message'],
    transmissionOutPortLabels: ['True', 'False'],
    title: 'Confirm Dialog',
    category: 'Output',
    description: 'Shows a confirmation dialog (returns true or false)'
},
{
    url: typeMap.toastMessage,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Message'],
    transmissionOutPortLabels: [''],
    title: 'Toast Message',
    category: 'Output',
    description: 'Shows a toast message to the user',
},
{
    url: typeMap.toastSuccessMessage,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Message'],
    transmissionOutPortLabels: [''],
    title: 'Toast Success Message',
    category: 'Output',
    description: 'Shows a success toast message to the user',
},
{
    url: typeMap.undo,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Message'],
    transmissionOutPortLabels: ['Undo', 'Default'],
    title: 'Show Undo',
    category: 'Output',
    description: 'Shows an undo message to the user, allow undo operation to be called'
},

{
    url: typeMap.beginForm,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Form Container Id'],
    transmissionOutPortLabels: [''],
    title: 'Begin Form',
    category: 'Input',
    description: 'Marker to indicate the beginning of a user form'
},

{
    url: typeMap.richText,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean'],
    inputPortLabels: ['Required', 'Label', 'Default Value'],
    transmissionOutPortLabels: [''],
    title: 'Rich Text',
    category: 'Input',
    description: 'Shows a question to a user that allows rich text input'
},

{
    url: typeMap.slide,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean'],
    inputPortLabels: ['Required', 'Label', 'Default Value', 'Min', 'Max'],
    transmissionOutPortLabels: [''],
    title: 'Slider',
    category: 'Input',
    description: 'Shows a question to the user that allows for a numeric input between to fixed values',
},

{
    url: typeMap.emailBox,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean'],
    inputPortLabels: ['Required', 'Label', 'Default Value'],
    transmissionOutPortLabels: [''],
    title: 'Email Box',
    category: 'Input',
    description: 'Shows a question to the user that allows for an email address input'
},

{
    url: typeMap.phoneBox,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean'],
    inputPortLabels: ['Required', 'Label', 'Default Value'],
    transmissionOutPortLabels: [''],
    title: 'Phone Box',
    category: 'Input',
    description: 'Shows a question to the user that allows for phone number input',
},

{
    url: typeMap.checkBox,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean'],
    inputPortLabels: ['Required', 'Label', 'Default Value'],
    transmissionOutPortLabels: [''],
    title: 'Check box',
    category: 'Input',
    description: 'Shows a question to a user that allows for yes/no or true/false input',
},

{
    url: typeMap.endForm,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: [],
    transmissionOutPortLabels: ['Complete', 'Incomplete'],
    title: 'End Form',
    category: 'Input',
    description: 'Indicates the end of a user defined form'
},

{
    url: typeMap.showMessage,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Label'],
    transmissionOutPortLabels: [''],
    title: 'Message',
    category: 'Output',
    description: 'Outputs a message to the user',
},

{
    url: typeMap.heading,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Label'],
    transmissionOutPortLabels: [''],
    title: 'Heading',
    category: 'Output',
    description: 'Shows a message to the user formatted as a heading'
},

{
    url: typeMap.separator,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: [],
    transmissionOutPortLabels: [''],
    title: 'Separator',
    category: 'Output',
    description: 'Creates a visible separator on a user defined form',
},
{
    url: typeMap.setPropertyValue,
    hasTransmissionLineIn: true,
    inputPortLabels: ['Object', 'Key', 'Value'],
    title: 'Set Property Value',
    category: 'Data',
    description: 'Sets the value on the specified object',
    transmissionOutPortLabels: [''],
    hasOutputPort: true,
},
{
    url: typeMap.saveObject,
    hasTransmissionLineIn: true,
    inputPortLabels: ['Object', 'Object Type'],
    inputPortTypes: ['', 'OBJECT-TYPE-SELECTOR'],
    title: 'Save Object',
    description: 'Saves the specified object to the database',
    category: 'Data',
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.tagEntry,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean', 'boolean', 'boolean', 'boolean', 'boolean', 'boolean', 'boolean', 'boolean'],
    inputPortLabels: ['Required', 'Job', 'Subscription', 'Entity', 'Task', 'SP Invoice', 'PO', 'Invoice'],
    transmissionOutPortLabels: [''],
    title: 'Tag Entry',
    category: 'Input',
    description: 'Allows for inputting tags',
},

{
    url: typeMap.preferredTimeSlot,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['boolean', 'WORK-ORDER-ITEM', null, 'boolean'],
    inputPortLabels: ['Required', 'Selected Item Id', 'Day Buffer', 'Hide 3rd', 'Label'],
    transmissionOutPortLabels: [''],
    title: 'Preferred Time Slots',
    category: 'Input',
    description: 'Allows for selecting preferred time slots',
},

{
    url: typeMap.createTask,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['EMPLOYEE', 'TASK_QUEUE', 'MULTILINE', '', 'OBJECT-TYPE', 'DATE'],
    inputPortLabels: ['Employee', 'Task Queue Id', 'Message', 'Object Id', 'Object Type', 'Follow-up Date'],
    transmissionOutPortLabels: [''],
    title: 'Create Task',
    category: 'Data',
    description: 'Creates a task to be completed',
},

{
    url: typeMap.createObject,
    hasTransmissionLineIn: false,
    hasOutputPort: true,
    inputPortLabels: [],
    transmissionOutPortLabels: [],
    title: 'Create Binding Object',
    category: 'Data',
    description: 'Creates a new object with user defined fields',
},

{
    url: typeMap.stringTemplateBinding,
    hasTransmissionLineIn: false,
    hasOutputPort: true,
    inputPortTypes: ['MULTILINE', null, 'TEMPLATE_PICKER', 'boolean', 'boolean'],
    inputPortLabels: ['Template', 'Object', 'Key', 'Inline CSS', 'Include Subject'],
    transmissionOutPortLabels: [],
    title: 'Bind Template',
    category: 'Data',
    description: 'Performes template binding on a string using an inputted object',
},

{
    url: typeMap.rest,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['', 'REST_METHOD', 'MULTILINE', 'boolean', 'EXTRACTION-METHOD'],
    inputPortLabels: ['URL', 'Method', 'Body', 'Use Node.js', 'Extraction Method'],
    transmissionOutPortLabels: [''],
    title: 'REST',
    category: 'Data',
    description: 'Performs a REST call to an API'
},
{
    url: typeMap.createConnectTask,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortTypes: ['CONNECT_TASK', '', 'MULTILINE', '', 'boolean'],
    inputPortLabels: ['Contact Flow Id', 'Task Name', 'Description', 'URL', 'Auto Navigate'],
    transmissionOutPortLabels: [''],
    title: 'Connect Task',
    category: 'Communication',
    description: 'Creates an Amazon Connect Task that will present to a user\'s Connect control panel'
},
{
    url: typeMap.showImage,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortTypes: ['', 'boolean'],
    inputPortLabels: ['URL', 'No Delay', 'height'],
    transmissionOutPortLabels: [''],
    title: 'Show Image',
    category: 'Output',
    description: 'Shows an image to the user',
},
{
    url: typeMap.youTube,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['URL'],
    transmissionOutPortLabels: [''],
    title: 'Play YouTube',
    category: 'Output',
    description: 'Shows a YouTube video to the user',
},
{
    url: typeMap.stringify,
    hasTransmissionLineIn: false,
    hasOutputPort: true,
    inputPortLabels: ['Object'],
    title: 'Stringify',
    category: 'Functions',
    description: 'Serializes an object into a JSON string'
}, {
    url: typeMap.parse,
    hasTransmissionLineIn: false,
    hasOutputPort: true,
    inputPortLabels: ['JSON'],
    title: 'Parse',
    category: 'Functions',
    description: 'Deserializes a JSON string into an object',
},
{
    url: typeMap.log,
    hasTransmissionLineIn: true,
    hasOutputPort: false,
    inputPortLabels: ['Value'],
    transmissionOutPortLabels: [''],
    title: 'console.log',
    category: 'Output',
    description: 'Outputs the specified value to the console.'
},
{
    url: typeMap.push,
    hasTransmissionLineIn: true,
    hasOutputPort: true,
    inputPortLabels: ['Array', 'New Value'],
    transmissionOutPortLabels: [''],
    title: 'Push',
    category: 'Array',
    description: 'Adds a new element to the end of an array',
},
{
    url: typeMap.arrayAccessor,
    hasOutputPort: true,
    inputPortTypes: ['', 'NUMBER'],
    inputPortLabels: ['Array', 'Index'],
    title: 'Array Accessor',
    category: 'Array',
    description: 'Get an element in an array at the specified index'
},
{
    url: typeMap.toString,
    hasOutputPort: true,
    inputPortLabels: ['Value'],
    title: 'ToString()',
    category: 'Functions',
    description: 'Converts a value to a string',
},
{
    url: typeMap.note,
    hasOutputPort: true,
    inputPortTypes: ['MULTILINE', '', '', '', '', '', 'boolean', 'boolean'],
    inputPortLabels: ['Note', 'Entity Id', 'Subscription Id', 'Job Id', 'Task Id', 'Attachments', 'Show On Contractor Portal', 'Pinned', 'HMS Property Id', 'HMS Service Id'],
    title: 'Create Note',
    category: 'Data',
    transmissionOutPortLabels: [''],
    hasTransmissionLineIn: true,
},
{
    url: typeMap.smsBotBegin,
    hasOutputPort: false,
    hasTransmissionLineIn: true,
    inputPortLabels: ['To #', 'From #', 'Object Id', 'Object Type', 'Timeout (seconds)'],
    transmissionOutPortLabels: ['', 'Timeout'],
    title: 'SMS Bot Begin',
    category: 'Communication',
    description: 'Begins an SMS bot conversation with a specified phone number',
},
{
    url: typeMap.smsBotEnd,
    hasOutputPort: false,
    hasTransmissionLineIn: true,
    inputPortLabels: [],
    transmissionOutPortLabels: [''],
    title: 'SMS Bot End',
    category: 'Communication',
    description: 'Ends an SMS bot conversation'
},
{
    url: typeMap.add,
    hasOutputPort: true,
    inputPortLabels: ['Value 1', 'Value 2'],
    title: 'Add',
    category: 'Math',
    description: 'Adds two values together',
},
{
    url: typeMap.subtract,
    hasOutputPort: true,
    inputPortLabels: ['Value 1', 'Value 2'],
    title: 'Subtract',
    category: 'Math',
    description: 'Subtracts one number from another',
},
{
    url: typeMap.multiply,
    hasOutputPort: true,
    inputPortLabels: ['Value 1', 'Value 2'],
    title: 'Multiple',
    category: 'Math',
    description: 'Multiplies two values together',
},
{
    url: typeMap.divide,
    hasOutputPort: true,
    inputPortLabels: ['Value 1', 'Value 2'],
    title: 'Divide',
    category: 'Math',
    description: 'Divides one number from another',
},
{
    url: typeMap.getDate,
    hasOutputPort: true,
    title: 'Get Date',
    category: 'Date/Time',
    description: 'Gets the current date/time'
},
{
    url: typeMap.dateAdd,
    hasOutputPort: true,
    title: 'Date Add',
    inputPortLabels: ['Date', 'Interval', 'Number'],
    inputPortTypes: ['DATE', 'DATEINTERVAL', 'NUMBER'],
    category: 'Date/Time',
    description: 'Returns a new date when an input date is added by a defined interval and number'
},
{
    url: typeMap.datePart,
    hasOutputPort: true,
    title: 'Date Part',
    inputPortLabels: ['Date', 'Part'],
    inputPortTypes: ['DATE', 'DATEINTERVAL'],
    category: 'Date/Time',
    description: 'Return the specified date part component',
},
{
    url: typeMap.phoneLink,
    hasOutputPort: false,
    title: 'Phone Link',
    inputPortTypes: ['', '', '', 'boolean'],
    inputPortLabels: ['Label', 'Phone #', 'Description', 'Auto-Dial'],
    category: 'Communication',
    description: 'Creates a clickable phone number link',
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.confirmButton,
    hasOutputPort: false,
    title: 'Confirm Button',
    inputPortLabels: ['Label'],
    category: 'Input',
    description: 'Creates a confirmation button that forces a user to click before continuing a questionnaire',
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: ['']
},
{
    url: typeMap.getEnvVariable,
    title: 'Get Environment Variable',
    inputPortLabels: ['Variable Name'],
    category: 'Data',
    description: 'Gets a variable by key stored in the environment',
    hasOutputPort: true,
},
{
    url: typeMap.saveEnvVariable,
    title: 'Save Environment Variable',
    inputPortLabels: ['Key', 'Value'],
    category: 'Data',
    description: 'Sets a variable stored in the environment',
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.changeJobStatus,
    title: 'Change Job Status',
    inputPortTypes: ['', 'WORK-ORDER-STATUS'],
    inputPortLabels: ['Job Id', 'Job Status', 'Job Line Id'],
    category: 'Data',
    description: 'Sets the job status',
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.parseInt,
    title: 'Parse Int',
    inputPortLabels: ['Value'],
    hasOutputPort: true,
    category: 'Functions',
    description: 'Parses a string to an integer',
},
{
    url: typeMap.parseFloat,
    title: 'Parse Float',
    inputPortLabels: ['Value'],
    hasOutputPort: true,
    category: 'Functions',
    description: 'Parses a string to an float',
},
{
    url: typeMap.getSubFunctionInputs,
    title: 'Get Sub Function Inputs',
    hasOutputPort: true,
    category: 'Data',
    description: 'Gets the arguments passed into the current frame of the sub function stack trace',
}, {
    url: typeMap.cleanUp,
    title: 'Clean-Up',
    category: 'Data',
    hasOutputPort: false,
    description: 'Deletes the current workflow',
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.getObject,
    title: 'Get Object',
    category: 'Data',
    hasOutputPort: true,
    description: 'Get an object by Id',
    inputPortLabels: ['Id', 'Object Type'],
    inputPortTypes: ['', 'OBJECT-TYPE-SELECTOR']
},
{
    url: typeMap.evaluate,
    title: 'Evaluate',
    category: 'Functions',
    hasOutputPort: true,
    description: 'Creates a JavaScript function and evaluates',
    inputPortLabels: ['Expression', 'Input Value'],
    inputPortTypes: ['MULTILINE'],
},
{
    url: typeMap.getAllVariables,
    title: 'All Variables',
    category: 'Data',
    hasOutputPort: true,
    description: 'Get all the session variables',
},
{
    url: typeMap.startChat,
    title: 'Start Chat',
    description: 'Start a chat session with the call center',
    category: 'Communication',
    hasOutputPort: false,
    hasTransmissionLineIn: true,
    inputPortLabels: ['ChatParticipantId', 'Channel', 'Contact Flow Id', 'Policy Id', 'Entity Id', 'Job Id', 'Initial Message', 'Function Id', 'Auto Start'],
    inputPortTypes: ['', '', 'CONNECT_TASK', '', '', '', '', '', 'boolean'],
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.stringConcat,
    title: 'String Concat',
    description: 'Concatonates two strings together',
    hasOutputPort: true,
    category: 'Data',
    inputPortLabels: ['String 1', 'String 2'],
    inputPortTypes: ['MULTILINE', 'MULTILINE']

},
{
    url: typeMap.starRating,
    title: '5 Star Rating',
    description: 'Show a 5 star rating',
    category: 'Input',
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    inputPortLabels: ['Label'],
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.getJobItem,
    title: 'Get Job Item',
    description: 'Get a list of the job items available on a subscription',
    category: 'Input',
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    inputPortLabels: ['Policy Id'],
    transmissionOutPortLabels: ['']
},
{
    url: typeMap.jobItemQuestions,
    title: 'Job Item Questions',
    description: 'Show the request service job item questions',
    category: 'Input',
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    inputPortLabels: ['Work Order Item Id', 'Policy Id'],
    transmissionOutPortLabels: ['']
},
{
    url: typeMap.getPolicies,
    title: 'Get Customer Subscriptions',
    description: 'Searches for the customer subscriptions',
    category: 'Input',
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    inputPortLabels: ['Phone', 'Email', 'No Match Message'],
    transmissionOutPortLabels: ['']
},
{
    url: typeMap.findPolicy,
    title: 'Search For Subscriptions',
    category: 'Input',
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    inputPortLabels: ['Single Select'],
    inputPortTypes: ['boolean'],
    transmissionOutPortLabels: ['']
},
{
    url: typeMap.getCreditCards,
    title: 'Get Credit Cards',
    description: 'Gets the credit cards attached to a customer',
    category: 'Input',
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    inputPortLabels: ['Entity Id', 'Label'],
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.newArray,
    title: 'New Array',
    description: 'Creates a new instance of an array',
    category: 'Array',
    hasOutputPort: true,
    inputPortLabels: ['Value 1', 'Value 2', 'Value 3', 'Value 4'],
},
{
    url: typeMap.confirmContact,
    title: 'Confirm Contact',
    description: 'Confirms the job contact information for a customer',
    category: 'Input',
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    inputPortLabels: ['Entity Id', 'Label'],
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.newid,
    title: 'New Id',
    description: 'Creates a globally unique identifier',
    category: 'Data',
    hasOutputPort: true,
},
{
    url: typeMap.ternaryOperator,
    title: 'Ternary Operator',
    description: 'Implements the ternary operator',
    category: 'Control Flow',
    inputPortLabels: ['Expression', 'True Value', 'False Value'],
    hasOutputPort: true,
},
{
    url: typeMap.hyperlink,
    title: 'Hyperlink',
    description: 'Shows a hyperlink to the user',
    category: 'Input',
    inputPortLabels: ['href', 'Text', 'Required', 'Label'],
    inputPortTypes: ['', '', 'boolean'],
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.stopConnectContact,
    title: 'Stop Connect Contact',
    description: 'Ends the specified Amazon Connect contact',
    category: 'Communication',
    inputPortLabels: ['Contact Id'],
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],
}, {
    url: typeMap.datesEqual,
    title: 'Dates Equal',
    description: 'Checks to see of the date portion of two dates are equal',
    category: 'Date/Time',
    inputPortLabels: ['Date 1', 'Date 2'],
    transmissionOutPortLabels: ['True', 'False'],
    hasTransmissionLineIn: true,

},
{
    url: typeMap.addressEntry,
    title: 'Address Entry',
    description: 'Entry form for a street address',
    category: 'Input',
    inputPortTypes: ['', 'boolean', 'boolean'],
    inputPortLabels: ['Label', 'Show Property Fields', 'Required'],
    transmissionOutPortLabels: [''],
    hasTransmissionLineIn: true,
    hasOutputPort: true,
},
{
    url: typeMap.planSelection,
    title: 'Plan Selection',
    description: 'Selects the available plans',
    category: 'Input',
    inputPortLabels: ['Coverage Type', 'Address', 'Default Monthly', 'New Construction'],
    inputPortTypes: ['', '', 'boolean', 'boolean'],
    transmissionOutPortLabels: [''],
    hasTransmissionLineIn: true,
    hasOutputPort: true,

},
{
    url: typeMap.clipboardCopy,
    title: 'Copy Clipboard',
    description: 'Copies the inputted value onto the user\'s clipboard',
    category: 'Data',
    inputPortLabels: ['Value'],
    transmissionOutPortLabels: [''],
    hasTransmissionLineIn: true,

},
{
    url: typeMap.aiChat,
    title: 'AI Chat',
    description: 'Start a chat with an AI Assistant',
    category: 'AI',
    inputPortLabels: ['Prompt', 'Representative Keyword', 'Intent Prompt'],
    inputPortTypes: ['MULTILINE', null, 'MULTILINE'],
    transmissionOutPortLabels: ['', 'Representative'],
    hasTransmissionLineIn: true,
    hasOutputPort: true,
},
{
    url: typeMap.customComponent,
    title: 'Custom Component',
    description: 'Loads a custom component',
    category: 'Input',
    inputPortLabels: ['Component Key', 'Object In Scope'],
    inputPortTypes: ['LAZY-COMPONENT-KEY'],
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: ['_Default', 'Cancelled'],
    hasOutputPort: true
},
{
    url: typeMap.checkCircleAnimated,
    title: 'Check Circle',
    description: 'Shows the animated check completion circle',
    category: 'Output',
    hasTransmissionLineIn: true,
    inputPortLabels: ['Label', 'Height'],
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.closeObjectDetail,
    title: 'Close Object Detail',
    description: 'Closes the object detail pane on boards and queues',
    category: 'UI',
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],

},
{
    url: typeMap.lottieFile,
    title: 'Lottie File',
    description: 'Shows a Lottie File',
    category: 'Output',
    hasTransmissionLineIn: true,
    inputPortTypes: ['', 'boolean', '', '', 'boolean'],
    inputPortLabels: ['URL', 'Loop', 'Height', 'Intermission', 'No Delay', 'Label'],
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.createAuthoItem,
    title: 'Create Autho Item',
    category: 'Autho',
    description: 'Creates an authorization line item',
    inputPortLabels: ['Job Line Id', 'Authorization Item(s)', 'Destination'],
    inputPortTypes: ['', 'authorization-item-selector'],
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.transferToRepairType,
    title: 'Transfer To Repair Type',
    category: 'Autho',
    description:'Transfers the current flow to the questions associated with the selected repair type',
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: ['']
},
{
    url: typeMap.authoItemSelection,
    title: 'Select Autho Items',
    category: 'Autho',
    description: 'Allows the end user to pick from a list of available authorization repair items',
    inputPortLabels: ['Label', 'Authorization Item(s)'],
    inputPortTypes: ['', 'authorization-item-selector'],
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: ['']
},
{
    url: typeMap.searchChipSelection,
    title: 'Search Chip Selection',
    category: 'Input',
    description: 'Shows a chip selection tied to the search index',
    inputPortLabels: ['Type', 'SubType', 'Label', 'Single Select', 'Required'],
    inputPortTypes: ['', '', '', 'boolean', 'boolean'],
    hasOutputPort: true,
    transmissionOutPortLabels: [''],
    hasTransmissionLineIn: true,
},
{
    url: typeMap.addAuthoWarning,
    title: 'Add Autho Warning',
    category: 'Autho',
    description: 'Add an authorization warning to the authorization process',
    inputPortLabels: ['Type', 'Description', 'Value', 'Show Externally', 'Overrwrites', 'Destination'],
    inputPortTypes: ['authorization-trigger-selector', '', '', 'boolean', 'boolean'],
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.workOrderItemTypeSelection,
    title: 'Select Job Item Type',
    category: 'Autho',
    description: 'Shows a selection list to the user to pick the job item type',
    inputPortLabels: ['Label'],
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],

},
{
    url: typeMap.selectRepairType,
    title: 'Select a Repair Type',
    category: 'Autho',
    description: 'Shows a selection for a repair type',
    inputPortLabels: ['Label'],
    hasOutputPort: true,
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],
},
{
    url: typeMap.addLabor,
    title: 'Add Labor',
    description: 'Add hours of labor to an authorization',
    category: 'Autho',
    inputPortLabels: ['Amount'],
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: ['']
},
{
    url: typeMap.fileUpload,
    title: 'File Upload',
    description: 'Upload files',
    category: 'Input',
    inputPortLabels: ['Label', 'Mime Type'],
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],
    hasOutputPort: true,
}, {
    url: typeMap.changeRepairType,
    title: 'Change Repair Type',
    description: 'Change the current repair type',
    category: 'Autho',
    inputPortLabels: ['Repair Type', 'Perform Transfer'],
    inputPortTypes: ['repair-type-selector', 'boolean'],
    hasTransmissionLineIn: true,
    transmissionOutPortLabels: [''],
}

];